import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';


import logoGAD from "./GADicon.png";
import Lock from '@mui/icons-material/LockOutlined';

import Home from './Home/Home';
import AboutYou from './AboutYou/AboutYou';
import YourSummary from './YourSummary/YourSummary';
import YourChoice from './YourChoice/YourChoice';
import FAQ from './FAQ/FAQ';
import TandCs from './T&Cs/TandCs';

import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Button from '@mui/material/Button';




function App() {

  const pageStyle = `
  @page {
    size: A3 landscape;
  }
`;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle
  });  

  // Global useState variables passed between pages on the site
  // Text for Your Choice, FAQ and T&Cs pages
  const [glob_text_yourChoice, glob_setText_yourChoice] = useState("");
  const [glob_text_faq, glob_setText_faq] = useState("");
  const [glob_text_tcTitles, glob_setText_tcTitles] = useState("");
  const [glob_text_tcContent, glob_setText_tcContent] = useState("");
  const [glob_textLoaded, glob_setTextLoaded] = useState(false);
  
  // About You page
  const [glob_started, glob_setStarted] = useState(false);
  const [glob_hasUserInputData, glob_setHasUserInputData] = useState(false);

  const [glob_employer, glob_setEmployer] = useState("");
  const [glob_scheme, glob_setScheme] = useState("");
  const [glob_region, glob_setRegion] = useState("");
  const [glob_dateOfBirth, glob_setDateOfBirth] = useState(null);
  const [glob_dateJoinedScheme, glob_setDateJoinedScheme] = useState(null);
  const [glob_dateLeftScheme, glob_setDateLeftScheme] = useState(null);
  const [glob_annualBenefitStatement, glob_setAnnualBenefitStatement] = useState(0);
  const [glob_salaryStartYear, glob_setSalaryStartYear] = useState(2015); //Note for Aaron - placeholder
  const [glob_salaries, glob_setSalaries] = useState([0,0,0,0,0,0,30000]); //Note for Aaron - placeholder
  const [glob_partTime, glob_setPartTime] = useState([{from: null, to: null, proportion: null},]);
  const [glob_isMemberOfMet, glob_setIsMemberOfMet] = useState();
  const [glob_rankAtRetirement, glob_setRankAtRetirement] = useState();

  // Constants - saved from backend
  const [glob_prevSchemeName, glob_setPrevSchemeName] = useState("");
  const [glob_currSchemeName, glob_setCurrSchemeName] = useState("");
  const [glob_ageSPA, glob_setAgeSPA] = useState();
  const [glob_ageVRA, glob_setAgeVRA] = useState();
  const [glob_currentAge, glob_setCurrentAge] = useState();

  // Scenario-specific parameters - saved from backend
  const [glob_retirementDates, glob_setRetirementDates] = useState([]);
  const [glob_retirementAges, glob_setRetirementAges] = useState([]);
  const [glob_careAges, glob_setCareAges] = useState([]);
  const [glob_deferredAge, glob_setDeferredAge] = useState([]);
  const [glob_careAgesMinimum, glob_setCareAgesMinimum] = useState([]);
  const [glob_careAgesMaximum, glob_setCareAgesMaximum] = useState([]);
  const [glob_dateLeftSchemeArray, glob_setDateLeftSchemeArray] = useState([]);
  const [glob_leftScheme, glob_setLeftScheme] = useState([]);
  const [glob_maxCommutationPrevScheme, glob_setMaxCommutationPrevScheme] = useState([]);
  const [glob_maxCommutationCurrScheme, glob_setMaxCommutationCurrScheme] = useState([]);
  const [glob_commutationFactorPrevScheme, glob_setCommutationFactorPrevScheme] = useState([]);
  const [glob_commutationFactorCurrScheme, glob_setCommutationFactorCurrScheme] = useState([]);
  const [glob_chosenCommutationProportionPrevScheme, glob_setChosenCommutationProportionPrevScheme] = useState([]);
  const [glob_chosenCommutationProportionCurrScheme, glob_setChosenCommutationProportionCurrScheme] = useState([]);
  const [glob_annualPensionPrevScheme, glob_setAnnualPensionPrevScheme] = useState([]);
  const [glob_annualPensionCurrScheme, glob_setAnnualPensionCurrScheme] = useState([]);
  const [glob_finalSalary, glob_setFinalSalary] = useState([]);

  // CPI - saved from backend
  const [glob_cpi, glob_setCPI] = useState();

  // Your Summary Page - Infographic data - saved from backend
  const [glob_infographicPrevSchemePension, glob_setInfographicPrevSchemePension] = useState([]);
  const [glob_infographicCurrSchemePensionInPayment, glob_setInfographicCurrSchemePensionInPayment] = useState([]);
  const [glob_infographicCurrSchemePensionLaterPayment, glob_setInfographicCurrSchemePensionLaterPayment] = useState([]);
  const [glob_infographicPrevSchemePensionNormalised, glob_setInfographicPrevSchemePensionNormalised] = useState([]);
  const [glob_infographicCurrSchemePensionInPaymentNormalised, glob_setInfographicCurrSchemePensionInPaymentNormalised] = useState([]);
  const [glob_infographicCurrSchemePensionLaterPaymentNormalised, glob_setInfographicCurrSchemePensionLaterPaymentNormalised] = useState([]);
  const [glob_infographicLumpSum2015, glob_setInfographicLumpSum2015] = useState([]);
  const [glob_infographicLumpSum1987, glob_setInfographicLumpSum1987] = useState([]);
  const [glob_infographicIntroduction, glob_setInfographicIntroduction] = useState([]);
  const [glob_infographicCareIntroduction, glob_setInfographicCareIntroduction] = useState([]);
  const [glob_infographicFinalSalaryCommutation, glob_setInfographicFinalSalaryCommutation] = useState([]);
  const [glob_infographicCareCommutation, glob_setInfographicCareCommutation] = useState([]);


  // Other information - saved from backend
  const [glob_titles, glob_setTitles] = useState([]);
  const [glob_minDeferredAge, glob_setMinDeferredAge] = useState();
  const [glob_maxDeferredAge, glob_setMaxDeferredAge] = useState(); 
  const [glob_annualPensionNominal, glob_setAnnualPensionNominal] = useState([]);
  const [glob_annualPensionReal, glob_setAnnualPensionReal] = useState([]);
  const [glob_annualPensionProportional, glob_setAnnualPensionProportional] = useState([]);
  const [glob_lumpSumNominal, glob_setLumpSumNominal] = useState([]);
  const [glob_lumpSumReal, glob_setLumpSumReal] = useState([]);
  const [glob_lumpSumProportional, glob_setLumpSumProportional] = useState([]);
  const [glob_lumpSumComparisonChart, glob_setLumpSumComparisonChart] = useState([])
  
  // Your Summary variables - saved from frontend
  // Flag whether to show the infographic or not
  const [glob_yourSummary_showJourneyInfographic, glob_yourSummary_setShowJourneyInfographic] = useState();
  // Colours
  const glob_yourSummary_colour1987Fill = "rgb(6,3,141)";
  const glob_yourSummary_colour1987Border = "rgba(6,3,141,50)";
  const glob_yourSummary_colour2015Fill = "rgb(237,139,0)";
  const glob_yourSummary_colour2015Border = "rgba(237,139,0,50)";
  const glob_yourSummary_colourLumpSumFill = "rgb(75,149,96)";
  const glob_yourSummary_colourLumpSumBorder = "rgba(75,149,96,50)";
  // Commutation
  const [glob_yourSummary_commutationProportion1987, glob_yourSummary_setCommutationProportion1987] = useState(1);
  const [glob_yourSummary_commutationProportion2015, glob_yourSummary_setCommutationProportion2015] = useState(1);
  // Transition Year
  const [glob_transitionYear, glob_setTransitionYear] = useState('2022');
  
  // Settings from /public/config.js

  // sets whether to use local or host url
  const LOCAL_SETTING = window.LOCAL_SETTING
  // local url
  const LOCAL_URL = window.LOCAL_URL
  // host url
  const HOST_URL = window.HOST_URL

  // url for API
  const glob_addressAPI = LOCAL_SETTING ? LOCAL_URL : HOST_URL 
  console.log('API is pointing to: ' + glob_addressAPI)

  // Theme colours
  // Overall theme colour
  const glob_themeColour_active = "#af292e";
  const glob_themeColour_disabled_dark = "#a9a9a9";
  const glob_themeColour_disabled_light = "#eaeaf0";

  const Home_Page = () =>{
    return <Home textLoaded={glob_textLoaded} setTextLoaded={glob_setTextLoaded} addressAPI={glob_addressAPI} callbackStarted={glob_setStarted} callbackTextYourChoice={glob_setText_yourChoice}
    callbackTextFAQ={glob_setText_faq} callbackTextTCsTitles={glob_setText_tcTitles} callbackTextTCsContent={glob_setText_tcContent}
    colourActive={glob_themeColour_active} colourDisabledDark={glob_themeColour_disabled_dark}/>
  }

  const About_You = () => {
      return <AboutYou addressAPI={glob_addressAPI} callbackEmployer={glob_setEmployer} callbackScheme={glob_setScheme} callbackRegion={glob_setRegion}
        callbackDateOfBirth={glob_setDateOfBirth} callbackDateJoinedScheme={glob_setDateJoinedScheme} 
        callbackDateLeftScheme={glob_setDateLeftScheme} callbackABS={glob_setAnnualBenefitStatement}
        callbackSalaryStartYear={glob_setSalaryStartYear} callbackSalaries={glob_setSalaries} callbackPartTime={glob_setPartTime} 
        callbackIsMemberOfMet={glob_setIsMemberOfMet} callbackRankAtRetirement={glob_setRankAtRetirement}
        callbackHasUserInputData={glob_setHasUserInputData} 
        callbackPrevSchemeName={glob_setPrevSchemeName} callbackCurrSchemeName={glob_setCurrSchemeName}
        callbackAgeSPA={glob_setAgeSPA} callbackAgeVRA={glob_setAgeVRA} 
        callbackDeferredAge={glob_setDeferredAge} //Note for Aaron - do we need this here?
        callbackRetirementDates={glob_setRetirementDates} callbackRetirementAges={glob_setRetirementAges}
        callbackCareAges={glob_setCareAges} callbackCareAgesMinimum={glob_setCareAgesMinimum} callbackCareAgesMaximum={glob_setCareAgesMaximum} 
        callbackDateLeftSchemeArray={glob_setDateLeftSchemeArray} callbackLeftScheme={glob_setLeftScheme} callbackMaxCommutationPrevScheme={glob_setMaxCommutationPrevScheme}
        callbackMaxCommutationCurrScheme={glob_setMaxCommutationCurrScheme} callbackCommutationFactorPrevScheme={glob_setCommutationFactorPrevScheme}
        callbackCommutationFactorCurrScheme={glob_setCommutationFactorCurrScheme}
        callbackChosenCommutationProportionPrevScheme={glob_setChosenCommutationProportionPrevScheme}
        callbackChosenCommutationProportionCurrScheme={glob_setChosenCommutationProportionCurrScheme}
        callbackAnnualPensionPrevScheme={glob_setAnnualPensionPrevScheme} callbackAnnualPensionCurrScheme={glob_setAnnualPensionCurrScheme}
        callbackFinalSalary={glob_setFinalSalary}
        callbackCpi={glob_setCPI} 
        callbackInfographicShow={glob_yourSummary_setShowJourneyInfographic}
        callbackInfographicPrevSchemePension={glob_setInfographicPrevSchemePension} callbackInfographicCurrSchemePensionInPayment={glob_setInfographicCurrSchemePensionInPayment}
        callbackInfographicCurrSchemePensionLaterPayment={glob_setInfographicCurrSchemePensionLaterPayment}
        callbackInfographicPrevSchemePensionNormalised={glob_setInfographicPrevSchemePensionNormalised}
        callbackInfographicCurrSchemePensionInPaymentNormalised={glob_setInfographicCurrSchemePensionInPaymentNormalised}
        callbackInfographicCurrSchemePensionLaterPaymentNormalised={glob_setInfographicCurrSchemePensionLaterPaymentNormalised}
        callbackInfographicLumpSum2015={glob_setInfographicLumpSum2015} callbackInfographicLumpSum1987={glob_setInfographicLumpSum1987}
        callbackInfographicIntroduction={glob_setInfographicIntroduction} callbackInfographicCareIntroduction={glob_setInfographicCareIntroduction} 
        callbackInfographicFinalSalaryCommutation={glob_setInfographicFinalSalaryCommutation}
        callbackInfographicCareCommutation={glob_setInfographicCareCommutation}
        callbackTitles={glob_setTitles}
        callbackMinDeferredAge={glob_setMinDeferredAge} callbackMaxDeferredAge={glob_setMaxDeferredAge}         
        callbackCurrentAge={glob_setCurrentAge}
        callbackAnnualPensionNominal={glob_setAnnualPensionNominal} callbackAnnualPensionReal={glob_setAnnualPensionReal}
        callbackAnnualPensionProportional={glob_setAnnualPensionProportional}
        callbackLumpSumNominal={glob_setLumpSumNominal} callbackLumpSumReal={glob_setLumpSumReal}
        callbackLumpSumProportional={glob_setLumpSumProportional}
        callbackLumpSumComparisonChart={glob_setLumpSumComparisonChart}
        colourActive={glob_themeColour_active} colourDisabledLight={glob_themeColour_disabled_light} colourDisabledDark={glob_themeColour_disabled_dark}
         />
  }

  const Your_Summary = () => {
    return <YourSummary scheme={glob_scheme} showJourneyInfographic={glob_yourSummary_showJourneyInfographic}
    prevSchemePension={glob_infographicPrevSchemePension} currSchemePensionInPayment={glob_infographicCurrSchemePensionInPayment}
    currSchemePensionLaterPayment={glob_infographicCurrSchemePensionLaterPayment} prevSchemePensionNormalised={glob_infographicPrevSchemePensionNormalised}
    currSchemePensionInPaymentNormalised={glob_infographicCurrSchemePensionInPaymentNormalised} currSchemePensionLaterPaymentNormalised={glob_infographicCurrSchemePensionLaterPaymentNormalised}
    lumpSum1987={glob_infographicLumpSum1987} lumpSum2015={glob_infographicLumpSum2015}
    introduction={glob_infographicIntroduction} careIntroduction={glob_infographicCareIntroduction}
    finalSalaryCommutation={glob_infographicFinalSalaryCommutation} careCommutation={glob_infographicCareCommutation}
    colour1987Fill={glob_yourSummary_colour1987Fill} colour1987Border={glob_yourSummary_colour1987Border}
    colour2015Fill={glob_yourSummary_colour2015Fill} colour2015Border={glob_yourSummary_colour2015Border}
    colourLumpSumFill={glob_yourSummary_colourLumpSumFill} colourLumpSumBorder={glob_yourSummary_colourLumpSumBorder}
    commutationProportion1987={glob_yourSummary_commutationProportion1987} callbackCommutationProportion1987={glob_yourSummary_setCommutationProportion1987}
    commutationProportion2015={glob_yourSummary_commutationProportion2015} callbackCommutationProportion2015={glob_yourSummary_setCommutationProportion2015}
    colourButton={glob_themeColour_active} retirementAges={glob_retirementAges}
    maxCommutationPrevScheme={glob_maxCommutationPrevScheme} maxCommutationCurrScheme={glob_maxCommutationCurrScheme}
    titles={glob_titles} transitionYear={glob_transitionYear} setTransitionYear={glob_setTransitionYear}
    /> 
  }

  const Your_Choice = () => {
      return <YourChoice addressAPI={glob_addressAPI} text={glob_text_yourChoice} employer={glob_employer} scheme={glob_scheme} region={glob_region} dateOfBirth={glob_dateOfBirth} 
        dateJoinedScheme={glob_dateJoinedScheme} dateLeftScheme={glob_dateLeftScheme} abs={glob_annualBenefitStatement}
        salaryStartYear={glob_salaryStartYear} salaries={glob_salaries} partTime={glob_partTime}
        isMemberOfMet={glob_isMemberOfMet} rankAtRetirement={glob_rankAtRetirement}
        
        cpi={glob_cpi} setCpi={glob_setCPI}
        prevSchemeName={glob_prevSchemeName} currSchemeName={glob_currSchemeName} setPrevSchemeName={glob_setPrevSchemeName} setCurrSchemeName={glob_setCurrSchemeName} 
        ageSPA={glob_ageSPA} ageVRA={glob_ageVRA} currentAge={glob_currentAge} setAgeSPA={glob_setAgeSPA} setAgeVRA={glob_setAgeVRA} setCurrentAge={glob_setCurrentAge}
        retirementDates={glob_retirementDates} retirementAges={glob_retirementAges} careAges={glob_careAges} setRetirementDates={glob_setRetirementDates} setRetirementAges={glob_setRetirementAges} setCareAges={glob_setCareAges}
        careAgesMinimum={glob_careAgesMinimum} careAgesMaximum={glob_careAgesMaximum} dateLeftSchemeArray={glob_dateLeftSchemeArray} //Note for Aaron - do we ever change these dates? NOTE for Shereen - add deferred age + callbacks
        setCareAgesMinimum={glob_setCareAgesMinimum} setCareAgesMaximum={glob_setCareAgesMaximum} setDateLeftSchemeArray={glob_setDateLeftSchemeArray}
        leftScheme={glob_leftScheme} maxCommutationPrevScheme={glob_maxCommutationPrevScheme} maxCommutationCurrScheme={glob_maxCommutationCurrScheme}
        setLeftScheme={glob_setLeftScheme} setMaxCommutationPrevScheme={glob_setMaxCommutationPrevScheme} setMaxCommutationCurrScheme={glob_setMaxCommutationCurrScheme}
        commutationFactorPrevScheme={glob_commutationFactorPrevScheme} commutationFactorCurrScheme={glob_commutationFactorCurrScheme}
        setCommutationFactorPrevScheme={glob_setCommutationFactorPrevScheme} setCommutationFactorCurrScheme={glob_setCommutationFactorCurrScheme}
        deferredAge={glob_deferredAge} setDeferredAge={glob_setDeferredAge}
        chosenCommutationProportionPrevScheme={glob_chosenCommutationProportionPrevScheme} setChosenCommutationProportionPrevScheme={glob_setChosenCommutationProportionPrevScheme}
        chosenCommutationProportionCurrScheme={glob_chosenCommutationProportionCurrScheme} setChosenCommutationProportionCurrScheme={glob_setChosenCommutationProportionCurrScheme}
        annualPensionPrevScheme={glob_annualPensionPrevScheme} annualPensionCurrScheme={glob_annualPensionCurrScheme} finalSalary={glob_finalSalary}
        setAnnualPensionPrevScheme={glob_setAnnualPensionPrevScheme} setAnnualPensionCurrScheme={glob_setAnnualPensionCurrScheme} setFinalSalary={glob_setFinalSalary}
        titles={glob_titles} minDeferredAge={glob_minDeferredAge} maxDeferredAge={glob_maxDeferredAge}
        setTitles={glob_setTitles} setMinDeferredAge={glob_setMinDeferredAge} setMaxDeferredAge={glob_setMaxDeferredAge}
        annualPensionNominal={glob_annualPensionNominal} setAnnualPensionNominal={glob_setAnnualPensionNominal}
        annualPensionReal={glob_annualPensionReal} setAnnualPensionReal={glob_setAnnualPensionReal}
        annualPensionProportional={glob_annualPensionProportional} setAnnualPensionProportional={glob_setAnnualPensionProportional}
        lumpSumNominal={glob_lumpSumNominal} setLumpSumNominal={glob_setLumpSumNominal}
        lumpSumReal={glob_lumpSumReal} setLumpSumReal={glob_setLumpSumReal} lumpSumProportional={glob_lumpSumProportional} setLumpSumProportional={glob_setLumpSumProportional}
        lumpSumComparisonChart={glob_lumpSumComparisonChart} transitionYear={glob_transitionYear}
        /> 
  }

  const F_A_Q = () =>{
    return <FAQ text={glob_text_faq}/>
  }

  const T_and_Cs = () =>{
    return <TandCs titles={glob_text_tcTitles} content={glob_text_tcContent}/>
  }

  useEffect(() => {
    document.title = "Firefighters' (Scotland) Retirement Calculator";
    let address = glob_addressAPI + "api/information_text";
    fetch(address)
    .then(response => response.json())
    .then(function (data) {
        
      glob_setText_yourChoice(data['YourChoice'])
      glob_setText_faq(data['faq'])
      glob_setText_tcTitles(data['tc_titles'])
      glob_setText_tcContent(data['tc_content'])
    })
}, [])

  return (

    <Router>
      
      <div className="app">
        <div className="app__header">

          <div className="app__header__logo">
            <img src={logoGAD} alt=""/>
            <span>Government <br/>Actuary's <br/>Department </span>
          </div>

          <div className="app__header__center">
            <div className="app__header__center__top">
            </div>
            <div className="app__header__center__title">
              <span>Firefighters' (Scotland) Retirement Calculator</span>
            </div>
            <div className="app__header__center__navbar">
              <nav className="app__header__center__navbar__items">
                <ul>
                  <li>
                    <NavLink exact activeClassName="navClicked" to="/" >Home</NavLink>
                  </li>

                  <li>
                    {glob_started === false ? (
                    <div className="nav__aboutyou">
                      <NavLink className="nav__aboutyou__text" exact activeClassName="navClicked" to="/about you">About You</NavLink>
                      <Lock className="nav__aboutyou__lock" />
                    </div>
                    ) : (
                    <div>
                      <NavLink exact activeClassName="navClicked" to="/about you">About You</NavLink>
                    </div>                      
                    )}
                  </li>

                  <li>
                    {glob_hasUserInputData === false ? (
                    <div className="nav__yoursummary">
                      <NavLink className="nav__yoursummary__text" exact activeClassName="navClicked" to="/your summary">Your Summary</NavLink>
                      <Lock className="nav__yoursummary__lock" />
                    </div>
                    ) : (
                      <NavLink exact activeClassName="navClicked" to="/your Summary">Your Summary</NavLink>
                    )}
                  </li>

                  <li>
                    {glob_hasUserInputData === false ? (
                    <div className="nav__yourchoice">
                      <NavLink className="nav__yourchoice__text" exact activeClassName="navClicked" to="/your choice">Your Choice</NavLink>
                      <Lock className="nav__yourchoice__lock" />
                    </div>
                    ) : (
                      <NavLink exact activeClassName="navClicked" to="/your choice">Your Choice</NavLink>
                    )}
                  </li>

                  <li>
                    <NavLink exact activeClassName="navClicked" to="/faq" id="navbar__faq">FAQ</NavLink>
                  </li>

                  <li>
                    <NavLink exact activeClassName="navClicked" to="/terms and conditions">T&Cs</NavLink>
                  </li>


                </ul>
              </nav>
                          </div>
          </div>

          <div className="app__header__right">
            <Button style={{color:glob_themeColour_active,
                    border:glob_themeColour_active,
                    borderStyle:'solid',
                    borderWidth:'1px',
                    minWidth:'110px',
                    height:'27px',
                    marginBottom:'7px',
                    borderRadius:'4px'}} fullWidth={false} onClick={handlePrint}>Print page</Button>

          </div>

        </div>

        <Switch ref={componentRef}>
          <Route exact path="/" component={Home_Page} />
          <Route exact path="/about you" component={About_You} />
          <Route exact path="/your summary" component={Your_Summary} />
          <Route exact path="/your choice" component={Your_Choice} />
          <Route exact path="/faq" component={F_A_Q} />
          <Route exact path="/terms and conditions" component={T_and_Cs} />
        </Switch>
        
        

      </div>
    </Router>
  );
}

export default App;
