import React from 'react';
import { useState } from 'react';
import "./YourSummary.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SliderWithSideButtonSingle from '../YourChoice/SliderWithSideButtonSingle'
import { useHistory, Link } from 'react-router-dom';

import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { colorTheme } from "../AboutYou/AboutYou.js"


function Infographic({  scheme, 
                        currentPanel, 
                        setCurrentPanel, 
                        index, 
                        age, 
                        commutationProportion1987,
                        commutationProportion2015,
                        prevSchemePensionNormalised,
                        currSchemePensionInPaymentNormalised,
                        currSchemePensionLaterPaymentNormalised,
                        maxCommutationPrevScheme,
                        maxCommutationCurrScheme,
                        prevSchemePension,
                        currSchemePensionInPayment,
                        currSchemePensionLaterPayment,
                        lumpSum1987,
                        lumpSum2015,
                        maxLumpSum, 
                        colourLumpSumBorder, 
                        colourLumpSumFill,
                        infographicWidth, 
                        colour1987Fill, 
                        colour2015Fill, 
                        colour1987Border, 
                        colour2015Border }) { 
        
        const transitionYears = ['2022','2015']

        const selectText = () => {
            setCurrentPanel(index)
        }

        return <div>
        <div className="infographic__bar__container">
        {transitionYears.map( (transitionYear, i) => (
            <InfographicBar
            scheme={scheme}
            scenario={index}
            index={i} callbackCurrentPanel={setCurrentPanel} currentPanel={currentPanel}
            age={age}
            annualPension1987Scaled={(1-commutationProportion1987/4)*prevSchemePensionNormalised[transitionYear]} 
            annualPension2015Scaled={(1-commutationProportion2015/4)*currSchemePensionInPaymentNormalised[transitionYear]} 
            annualPension2015LaterScaled={(1-commutationProportion2015/4)*currSchemePensionLaterPaymentNormalised[transitionYear]}
            annualPension1987={ (1-commutationProportion1987*maxCommutationPrevScheme) * prevSchemePension[transitionYear]}
            annualPension2015={ (1-commutationProportion2015*maxCommutationCurrScheme) * currSchemePensionInPayment[transitionYear]}
            annualPension2015Later={ (1-commutationProportion2015*maxCommutationCurrScheme) * currSchemePensionLaterPayment[transitionYear]}
            annualPensionHeight={400} infographicWidth={120}
            lumpSumScaled={0.5}
            maxLumpSum = {maxLumpSum[transitionYear]}
            lumpSum = {commutationProportion1987*lumpSum1987[transitionYear]+commutationProportion2015*lumpSum2015[transitionYear]}
            colour1987Fill={colour1987Fill} colour2015Fill={colour2015Fill} colour1987Border={colour1987Border} colour2015Border={colour2015Border}
            lumpSumFill={colourLumpSumFill} lumpSumBorder={colourLumpSumBorder} transitionYear={transitionYear}
            />
        ))}
        </div>
        <div className="infographic__icon" width={infographicWidth} align="center">
                <Typography sx={{display:'block',textAlign:'center',fontSize:'16px', fontWeight:'bold', border: currentPanel === index && 'solid #af292e 1px'}} onClick={selectText}>
                    Age {age}  
                </Typography>

            {currentPanel === index && ( <ArrowDownwardOutlinedIcon sx={{ 
                margin: "4px",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "4px",
                boxSizing: "content-box",
                color: "white", 
                backgroundColor: "#af292e", 
                fontSize:"20" }}/>)}
        </div>
        
    </div> 
};


function InfographicBar({ scheme, scenario, currentPanel, callbackCurrentPanel, index, age, annualPension1987Scaled, annualPension2015Scaled ,
                        annualPension2015LaterScaled,
                        annualPension1987, annualPension2015, annualPension2015Later,
                        maxLumpSum, lumpSum, lumpSumBorder, lumpSumFill,
                        annualPensionHeight, infographicWidth, colour1987Fill, colour2015Fill, colour1987Border, colour2015Border, transitionYear }) { 
    
    const marginLeft = 10
    const width = infographicWidth
    const lumpSumCenter = width*0.5 + marginLeft
    const textMarginLeft = lumpSumCenter
    const marginLeftLater = 40
    
    const totalHeight = annualPensionHeight + infographicWidth + 100
    const baseHeight = totalHeight - 30

    const height1987 = annualPension1987Scaled*annualPensionHeight
    const height2015 = annualPension2015Scaled*annualPensionHeight
    const height2015Later = annualPension2015LaterScaled*annualPensionHeight
    
    const minRadius = width*0.3
    const radius = minRadius + (lumpSum/maxLumpSum)*width*0.2

    const selectText = () => {
        callbackCurrentPanel(scenario)
    }

    return <svg style={{cursor:"pointer"}} width = {infographicWidth*1.5} height = { totalHeight + 10} onClick={selectText}>
                    {/* <g>
                        {currentPanel === index && (
                            <rect x={marginLeft} width={width} y = {totalHeight-25} height={20} stroke="#af292e" fill="none"/>
                        )}
                        <text x={lumpSumCenter} y = {totalHeight-10} textAnchor="middle" fontFamily="Verdana" fontSize="13" fontWeight="bold" fill="black"> {`Age ${age}`} </text>

                        
                    </g> */}
                    <g>
                        <text x={lumpSumCenter} y = {totalHeight-10} textAnchor="middle" fontFamily="Verdana" fontSize="13" fontWeight="bold" fill="black"> {transitionYear==='2015'?'Reformed':'Legacy'} </text>  
                    </g>
                    <g>      
                        {/* lumpSum */}
                        
                        {lumpSum > 0 ? (
                            <g>
                                <circle cx={lumpSumCenter} cy = {100} r={radius} fill={lumpSumFill} stroke={lumpSumBorder} opacity={currentPanel === scenario ? ("1.0") : ("0.5")}/>
                                <text x={lumpSumCenter} y = {95} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">Cash</text>
                                <text x={lumpSumCenter} y = {115} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">
                                    {"£" + Math.round(`${lumpSum}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </text>
                            </g>
                            ):
                            (
                            <g>
                                <circle cx={lumpSumCenter} cy = {100} r={radius} fill="grey" stroke="black" opacity={currentPanel === scenario ? ("1.0") : ("0.5")}/>
                                <text x={lumpSumCenter} y = {95} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">No</text>
                                <text x={lumpSumCenter} y = {110} textAnchor="middle" fontFamily="Verdana" fontSize="10" fill="white">Lump sum</text>
                            </g> 
                            )
                        }
                        
                    </g>

                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>{scheme} Pension</Typography>
                            <Typography>{"£" + Math.round(`${annualPension1987}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 1987 */}
                        <rect 
                        x={marginLeft}
                        width={width}
                        height={height1987}
                        fill={colour1987Fill}
                        stroke={colour1987Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}
                        y = {baseHeight - height1987}
                        />
                        {height1987 > 55 && ( <g>
                        <text x={textMarginLeft} y = {baseHeight - height1987 + height1987/3} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">{scheme}</text>
                        <text x={textMarginLeft} y = {baseHeight - height1987 + height1987/3 + 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">Pension</text>
                        <text x={textMarginLeft} y = {baseHeight - height1987 + height1987/3 + 40} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">
                            {"£" + Math.round(`${annualPension1987}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                        </text>
                        </g>)}
                    </g>
                    </Tooltip>
                    
                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>2015 Pension</Typography>
                            <Typography>{"£" + Math.round(`${annualPension2015}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 2015 */}
                        <rect 
                        x={marginLeft}
                        width={width}
                        height={height2015}
                        fill={colour2015Fill}
                        stroke={colour2015Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}
                        y = {baseHeight - height1987 - height2015}
                        />
                        {annualPension2015 > 0 && (
                            <g>
                                <text x={textMarginLeft} y = {baseHeight - height1987 - height2015 - 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">2015 Pension</text>
                                <text x={textMarginLeft} y = {baseHeight - height1987 - height2015 - 5} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">
                                    {"£" + Math.round(`${annualPension2015}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                                </text>
                            </g>
                            )
                        }
                    </g>
                    </Tooltip>

                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>2015 Pension</Typography>
                            <Typography>paid at age 55</Typography>
                            <Typography>{"£" + Math.round(`${annualPension2015Later}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 2015 Later */}
                        <rect 
                        x={marginLeftLater}
                        width={width}
                        height={height2015Later}
                        fill={colour2015Fill}
                        stroke={colour2015Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}

                        y = {baseHeight - height1987 - height2015 - height2015Later}
                        />
                        {annualPension2015Later > 0 && (
                            <g>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 35} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">2015 Pension</text>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">paid at age 55</text>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 5} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">
                                    {"£" + Math.round(`${annualPension2015Later}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                                </text>
                            </g>
                            )
                        }
                    </g>
                    </Tooltip>
                </svg>  
};

function YourSummary( {scheme, showJourneyInfographic, prevSchemePension, currSchemePensionInPayment, currSchemePensionLaterPayment,
    prevSchemePensionNormalised, currSchemePensionInPaymentNormalised, currSchemePensionLaterPaymentNormalised,
    lumpSum1987, lumpSum2015, introduction, careIntroduction, finalSalaryCommutation, careCommutation,
    colour1987Fill, colour1987Border, colour2015Fill, colour2015Border, colourLumpSumFill, colourLumpSumBorder,
    commutationProportion1987, callbackCommutationProportion1987, commutationProportion2015, callbackCommutationProportion2015, colourButton, retirementAges,
    maxCommutationPrevScheme, maxCommutationCurrScheme, titles, transitionYear, setTransitionYear } ) {
        
    const history = useHistory();
    const [currentPanel, setCurrentPanel] = useState(0);

    const commutationInformation = <p> Members can exchange (commute) up to one quarter of their pension for a cash lump sum. 
        Please note that restrictions on pension commutation are also imposed by the pension taxation regime under Finance Act 2004. 
        The main taxation restriction is that for a lump sum to be authorised under the tax rules it must not exceed 25% of the 
        total value of benefits crystallised. Please refer to HMRC guidance for further information. This calculator does not make 
        any allowance for the pensions tax regime.</p>

    const commutationInformationSpecial = <p>Members can exchange (commute) up to one quarter of their pension for a cash lump sum 
        provided the resultant lump sum would not be greater than the maximum permitted by HMRC. This limit is most likely to be 
        exceeded if the relevant commutation factor is greater than 20. No allowance has been made for this restriction and as such 
        members may be restricted to being able to commute less than 25% of their pension at retirement.</p>


    const handleSubmit = () => {
        
        history.push("your choice")
    }
    const doNothing = () => {

    }
    
    let maxLumpSum = {'2015':0, '2022':0}; 
    let transitionYears = ['2015','2022'];

    for (let i = 0; i < lumpSum1987.length; i++) {
        transitionYears.forEach( (ty) => {
          if (lumpSum1987[i][ty]+lumpSum2015[i][ty] > maxLumpSum[ty]){
                maxLumpSum[ty] = lumpSum1987[i][ty]+lumpSum2015[i][ty]
            }   
        })
    }
    //console.log({'transitionyear': transitionYear, 'maxlumpsum': maxLumpSum[transitionYear] })

    let commutationPrevSchemeLabel = "Cash lump sum in " + scheme + " scheme (%)";

    return (
        <div className="graph__container" >
            {showJourneyInfographic ? (
                <div>

 

                    <div className="title__container">
                        <h3> Pension and lump sum amounts at Key Milestones </h3>
                        <p>The chart below illustrates the annual pension and lump sum you may receive from both the {scheme} and 
                        2015 Schemes at different retirement ages depending on whether you remain in your legacy scheme or 
                        move to the reformed scheme for the period 1 April 2015 to 31 March 2022. Figures are shown in today’s money terms.</p>
                        <p>The scenarios below use an illustrative set of assumptions for CARE revaluation (3.25%) and earnings 
                            inflation (3.25%). As these factors can impact the outcome of which scheme provides higher benefits 
                            during the remedy period, the <Link to="/your choice">Your Choice</Link> section includes functionality to vary the relationship 
                            between these assumptions by changing the earnings inflation assumption.</p>
                    </div>

                    <div className="description__container">
                            <div className="description__payments">
                                <h3>Click on any of the scenarios below to see further information in the text box below the image</h3>
                                <h3>Please use the sliders to the {prevSchemePension.length > 3 ? "top" : "right"} of the chart to see how your pension and lump sum amounts change based on how much lump sum you wish to take</h3>
                            </div>
                    </div>

                <div className={prevSchemePension.length > 3 ? "infographic__container__parent__column" : "infographic__container__parent__row"}> 
                    <div className="infographic__container">                
                        {prevSchemePension.map( (prevPension, i) => 
                            <div className="infographic" key={i}>
                                <Infographic
                                scheme={scheme}
                                index={i} setCurrentPanel={setCurrentPanel} currentPanel={currentPanel}
                                age={retirementAges[i]}

                                commutationProportion1987={commutationProportion1987}
                                commutationProportion2015={commutationProportion2015}
                                prevSchemePensionNormalised={prevSchemePensionNormalised[i]}
                                currSchemePensionInPaymentNormalised={currSchemePensionInPaymentNormalised[i]}
                                currSchemePensionLaterPaymentNormalised={currSchemePensionLaterPaymentNormalised[i]}
                                maxCommutationPrevScheme={maxCommutationPrevScheme[i]}
                                maxCommutationCurrScheme={maxCommutationCurrScheme[i]}
                                prevSchemePension={prevSchemePension[i]}
                                currSchemePensionInPayment={currSchemePensionInPayment[i]}
                                currSchemePensionLaterPayment={currSchemePensionLaterPayment[i]}
                                lumpSum1987={lumpSum1987[i]}
                                lumpSum2015={lumpSum2015[i]}

                                annualPensionHeight={400} 
                                infographicWidth={120}
                                lumpSumScaled={0.5}

                                maxLumpSum = {maxLumpSum}

                                colour1987Fill={colour1987Fill} 
                                colour2015Fill={colour2015Fill} 
                                colour1987Border={colour1987Border} 
                                colour2015Border={colour2015Border}
                                colourLumpSumFill={colourLumpSumFill} 
                                colourLumpSumBorder={colourLumpSumBorder}
                                />
                            </div>
                            )
                        }
                        </div>

                    <div className="slider__container__summary">

                        {maxCommutationPrevScheme[0] > 0 &&
                        <SliderWithSideButtonSingle
                            parameter={commutationProportion1987}
                            setParameter={callbackCommutationProportion1987}
                            parameterName={commutationPrevSchemeLabel}
                            parameterMinValue={0}
                            parameterMaxValue={1} 
                            parameterDisplayMultiplicationFactor={100}
                            parameterRoundingDP={0}
                            parameterStepSize={0.01}
                            parameterInformation={scheme === "2006 Special" ? commutationInformationSpecial : commutationInformation}
                            requestUpdatedData={doNothing}
                        />}
                        {maxCommutationCurrScheme[0] > 0 &&
                        <SliderWithSideButtonSingle
                            parameter={commutationProportion2015}
                            setParameter={callbackCommutationProportion2015}
                            parameterName="Cash lump sum in 2015 scheme (%)"
                            parameterMinValue={0}
                            parameterMaxValue={1} 
                            parameterDisplayMultiplicationFactor={100}
                            parameterRoundingDP={0}
                            parameterStepSize={0.01}
                            parameterInformation={commutationInformation}
                            requestUpdatedData={doNothing}
                            />}
                        </div>
                        
                    
                </div>

                    <div className="description__container">
                        <div className="description__payments">
                            <h4> { titles[currentPanel] } </h4>
                            <p>
                            <Typography sx={{display:'block',textAlign:'left',fontSize:'16px', fontWeight:'bold'} }>
                                If you remain in your <u>legacy</u> Scheme until 2022  
                            </Typography>    
                            </p>
                            
                            <p> { introduction[currentPanel]['2022'] } </p>
                            <p>
                            <Typography sx={{display:'block',textAlign:'left',fontSize:'16px', fontWeight:'bold'}}>
                                If you move to the <u>reformed</u> Scheme in 2015  
                            </Typography>                                 
                            </p>
                           
                            <p> { introduction[currentPanel]['2015'] } </p>
                            <p> { careIntroduction[currentPanel][transitionYear] } </p>
                        </div>
                        <div className="description__payments">
                            <h4> Lump Sum </h4>
                            <p> { finalSalaryCommutation[currentPanel][transitionYear] } </p>
                            <p> { careCommutation[currentPanel][transitionYear] } </p>
                        </div>
                    </div>



                    <div className="button__container">
                        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, alignItems: 'center' }}>
                            <Box sx={{ flex: '1 0 auto' }} />
                            <Button style={{color:colourButton,border:colourButton,borderStyle:'solid',borderWidth:'1px'}} fullWidth={false} onClick={handleSubmit}>Continue</Button>
                        </Box>
                        <br></br>
                    </div>
                </div>
            ):(
                <div className="title__container">
                    <h3> Pension and lump sum amounts at Key Milestones </h3>
                    <p>This page is only applicable to active members of the scheme</p>
                    <p>If you have left the scheme, you will not be able to see the journey</p>
                    <p>Please navigate to Your Choice by clicking on the tab above</p>
                </div>
            )}
            
        </div>             
    )
}

export default YourSummary;